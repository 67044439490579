import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Loader from 'components/Loader';
import { Slate } from 'components/Slate';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { createSessionID, getUrlParams, scrollToTop } from 'helpers/utils';
import logFirebaseEvent from 'helpers/logFirebaseEvent';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { getRecipientsState } from 'selectors/recipients';
import {
  clearAccessToken,
  clearDonationSelections,
  getSessionID,
  setSessionID,
} from 'services/storage';
import { getRecipients } from 'store/recipients/actions';
import { DesktopLeftContainer } from './DesktopLeftContainer';
import { MainContainer } from './styled';
import { CandidateType } from 'components/Slate/Candidates/Candidates';
import { useIsOathCheckoutEnabled, useOathCheckout } from 'hooks/donate';
import {
  democracyEngineCheckoutRedirect,
  engageLabsCheckoutRedirect,
  getCandidates,
  getCandidatesByImpactScore,
  getCandidatesWithNewAmounts,
  getDonationSelectionsObj,
  getProcessingRedirect,
  getSelectedCandidates,
  logFirebaseEventDonateIntent,
  validateDonation,
} from 'helpers/donate';
import Error from 'components/Error';
import { Body, H3 } from 'components/Typography';
import { getUserState } from 'selectors/user';
import { getUserProfile } from 'store/user/actions';
import { useAuth } from 'hooks/auth';
import { DonateModal } from 'components/Modal/DonateModal';
import { PausedModal } from 'components/Modal/PausedModal';
import { getDonateConfig } from 'selectors/siteConfig';
import { IPageType, ProcessingRedirect } from 'store/pages/types';
import { ProcessingRedirectOptions } from 'store/pages/constants';
import { getAllPages, getPagesState } from 'selectors/pages';
import { getPages } from 'store/pages/actions';
import { getFilters } from 'store/filters/actions';

const MAX_RECIPIENTS = 200;
const MIN_RECIPIENTS = 6;

export const MainScreen = () => {
  const dispatch = useAppDispatch();
  const [refWidthValue, setRefWidthValue] = useState(0);
  const { urlTags, urlRef, urlPartner } = getUrlParams();
  const [processingRedirect, setProcessingRedirect] = useState<ProcessingRedirect>(
    ProcessingRedirectOptions.DE_OATH
  );
  const [pageType, setPageType] = useState<IPageType | undefined>();
  const isOathCheckoutEnabled = useIsOathCheckoutEnabled();
  const { recipients, pagesState, allPages, userState, donatModalConfig } = useAppSelector(
    state => ({
      recipients: getRecipientsState(state),
      pagesState: getPagesState(state),
      allPages: getAllPages(state),
      userState: getUserState(state),
      donatModalConfig: getDonateConfig(state, 'donate_modal'),
    })
  );
  const [selectedDonationAmount, setSelectedDonationAmount] = useState<number | undefined>();
  const [candidates, setCandidates] = useState<CandidateType[]>([]);
  const [showCustomSplit, setShowCustomSplit] = useState(false);
  const oathCheckout = useOathCheckout();

  useAuth(() => dispatch(getUserProfile()));

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (userState.error) clearAccessToken();
  }, [userState]);

  useEffect(() => {
    if (!allPages) {
      if (urlPartner) dispatch(getPages({ urlPartner }));
      dispatch(getFilters());
      clearDonationSelections();
    }
    if (!recipients.data && !urlPartner) {
      dispatch(
        getRecipients({
          amount: urlPartner ? MAX_RECIPIENTS : MIN_RECIPIENTS,
          is_public_searchable: true,
        })
      );
    }
    if (!getSessionID()) {
      setSessionID(createSessionID(10));
    }
    logFirebaseEvent('land_visitor', {
      timestamp: new Date().toString(),
      ref: urlRef,
      tags: urlTags,
      partner: urlPartner,
      session_id: getSessionID(),
    });
  }, []);

  useEffect(() => {
    if (!recipients.data && urlPartner && allPages?.length) {
      dispatch(
        getRecipients({
          amount: MAX_RECIPIENTS,
          recipient_ids: allPages[0].recipient_ids || [],
        })
      );
    }
  }, [allPages]);

  useEffect(() => {
    const { candidates: savedCandidates, total: savedDonationAmount } = getDonationSelectionsObj();
    if (savedDonationAmount) {
      setSelectedDonationAmount(Number(savedDonationAmount));
    }
    if (recipients?.data?.recipients && !urlPartner && !savedCandidates) {
      setCandidates(getCandidatesByImpactScore(recipients.data.recipients));
    } else if (recipients?.data?.recipients && !candidates.length) {
      setCandidates(getCandidates(recipients.data.recipients, savedCandidates));
    }
  }, [recipients]);

  useEffect(() => {
    if (allPages?.length) {
      const updatedProcessingRedirect = getProcessingRedirect(allPages);
      const updatedPageType =
        allPages?.findIndex(page => page.page_type === 'LIST') >= 0
          ? 'LIST'
          : allPages[0].page_type;
      setProcessingRedirect(updatedProcessingRedirect);
      setPageType(updatedPageType);
    }
  }, [allPages]);

  const onCustomSplit = (isCustomSplit: boolean) => {
    setShowCustomSplit(isCustomSplit);
  };

  const onUserDonationTotalChange = (amount?: number) => {
    setSelectedDonationAmount(amount);
    setCandidates(getCandidatesWithNewAmounts(amount, candidates, onCustomSplit));
  };

  const onSelectCandidates = (updatedCandidates: CandidateType[]) => {
    setCandidates(
      getCandidatesWithNewAmounts(selectedDonationAmount, updatedCandidates, onCustomSplit)
    );
  };

  const onDonateClick = (selectedFilters?: string[]) => {
    const selectedCandidates = getSelectedCandidates(candidates);
    const isDonationValid = validateDonation(selectedCandidates, selectedDonationAmount);
    if (!isDonationValid || !selectedDonationAmount) {
      return;
    }
    logFirebaseEventDonateIntent(selectedDonationAmount, candidates, selectedFilters);
    if (isOathCheckoutEnabled) {
      oathCheckout(selectedCandidates, selectedDonationAmount, selectedFilters);
    } else if (
      processingRedirect === ProcessingRedirectOptions.DE_GENERAL ||
      processingRedirect === ProcessingRedirectOptions.DE_OATH
    ) {
      const queryStrList = allPages
        ?.filter(page => selectedFilters?.includes(page.value))
        ?.map(page => page.query_str);
      democracyEngineCheckoutRedirect(selectedCandidates, selectedDonationAmount, '', queryStrList);
    } else if (processingRedirect === ProcessingRedirectOptions.EN_GENERAL) {
      engageLabsCheckoutRedirect(selectedCandidates, selectedDonationAmount);
    }
  };

  const isLoadingPages = urlPartner && !allPages;
  const isMissingPages = urlPartner && !allPages?.length;
  const hasApiError = pagesState.error || recipients.error;

  return (
    <>
      {(!allPages?.length ||
        !urlPartner ||
        (allPages?.length > 0 && !allPages[0].whitelabeled)) && <Header urlPartner={urlPartner} />}
      {!hasApiError && isLoadingPages ? (
        <div className="loader-center">
          <Loader width={60} height={60} />
        </div>
      ) : (
        <MainContainer>
          {hasApiError || isMissingPages ? (
            <Error
              data={{ component: 'Main' }}
              errorMsg="API failure on donate page"
              shouldAlert={!!hasApiError}
            >
              <H3>
                {hasApiError ? 'An error has occured. Contact us.' : 'Donate page data not found.'}
              </H3>
            </Error>
          ) : (
            <>
              {allPages &&
                (urlPartner && allPages[0].status === 'PAUSED' ? (
                  <PausedModal paused_misc={allPages[0].paused_misc} />
                ) : (
                  allPages[0].status === 'ACTIVE' &&
                  allPages[0].owner === 'OATH' &&
                  !donatModalConfig?.donate_modal_link.includes(`p=${urlPartner}`) &&
                  donatModalConfig?.donate_modal_config === 'on' && <DonateModal />
                ))}
              {pageType !== 'MEDIA' && <DesktopLeftContainer setRefWidthValue={setRefWidthValue} />}
              <ErrorBoundary
                fallback={
                  <Error
                    data={{ component: 'Slate' }}
                    errorMsg="Failure with donate Slate component"
                    shouldAlert={true}
                  >
                    <Body>We encountered an error. Please contact support.</Body>
                  </Error>
                }
              >
                <Slate
                  allPages={allPages}
                  recipients={recipients?.data?.recipients}
                  candidates={candidates}
                  onSelectCandidates={onSelectCandidates}
                  selectedDonationAmount={selectedDonationAmount}
                  onDonateClick={onDonateClick}
                  onUserDonationTotalChange={onUserDonationTotalChange}
                  setCandidates={setCandidates}
                  setSelectedDonationAmount={setSelectedDonationAmount}
                  showCustomSplit={showCustomSplit}
                  refWidthValue={refWidthValue}
                  type={pageType || 'LIST'}
                />
              </ErrorBoundary>
            </>
          )}
        </MainContainer>
      )}
      <Footer />
    </>
  );
};
