import { FC, useEffect, useState } from 'react';
import { Details } from './Details';
import { Payment } from './Payment';
import { Review } from './Review';
import { Wizard } from './Wizard';
import { Row } from 'components/Row';
import { Body, BodySmall } from 'components/Typography';
import theme from 'config/theme';
import { NavigationAmountDetails, NavigationContainer, OlNavigation, ProgressBar } from '../styled';
import { formatNumberAsCurrency, getPathWithRef, getUrlParams, isKeyInObject } from 'helpers/utils';
import { CandidateType } from 'components/Slate/Candidates/Candidates';
import { IUserData } from 'store/user/types';
import {
  getDemocracyEngineCheckoutUrl,
  getUrlSearchParamsStrWithoutRecipients,
  saveDonationSelections,
} from 'helpers/donate';
import { PaymentsData } from 'store/payments/types';
import {
  InitialValuesInterface,
  checkoutLogFirebaseEvent,
  getFormErrors,
  getInitialActivePageIndex,
  getInitialValuesFromApiData,
  getInitialValuesFromLastPayment,
  getIsCheckoutMonthly,
  getIsEmployerAddressRequiredByCandidates,
  getPayRequestBody,
  getProgressBarWidth,
} from './utils';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { postPayWithToken } from 'store/pay/actions';
import { postPayment, postPaymentAPI, postPaymentLoggedOut } from 'store/payments/actions';
import { getNewPaymentsById, getPaymentsState } from 'selectors/payments';
import { getDETokensById } from 'selectors/democracyEngine';
import { DETokenResponseData } from 'store/democracyengine/types';
import { getAllPages } from 'selectors/pages';
import { getPayData } from 'selectors/pay';
import { handleStop2EndDonationIfNeeded, getIsInStop2End } from 'helpers/stop2end';
import { FORM_ERROR } from 'final-form';
import { getFiltersData } from 'selectors/filters';

const formPages = [
  {
    navigationTitle: '2. Personal Details',
  },
  {
    navigationTitle: '3. Payment Method',
  },
  {
    navigationTitle: '4. Review',
  },
];

interface Props {
  candidates: CandidateType[];
  donationAmount: number;
  payments: PaymentsData[];
  user?: IUserData;
}

export const CheckoutForm: FC<Props> = ({ candidates, donationAmount, payments = [], user }) => {
  const { urlDonateAgain } = getUrlParams();
  const [activePageIndex, setActivePageIndex] = useState(0);
  const [initialValues, setInitalValues] = useState({});
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [democracyEngineCheckoutUrl, setDemocracyEngineCheckoutUrl] = useState('');
  const { newPaymentsById, deTokens, saveNewPaymentIsLoading, payment, pages, filters } =
    useAppSelector(state => ({
      newPaymentsById: getNewPaymentsById(state),
      saveNewPaymentIsLoading: getPaymentsState(state).saveNewPaymentIsLoading,
      deTokens: getDETokensById(state),
      payment: getPayData(state),
      pages: getAllPages(state),
      filters: getFiltersData(state),
    }));
  const [tokensToSave, setTokensToSave] = useState([]);

  useEffect(() => {
    if (candidates && donationAmount) {
      setDemocracyEngineCheckoutUrl(getDemocracyEngineCheckoutUrl(candidates, donationAmount));
    }
  }, [candidates, donationAmount]);

  useEffect(() => {
    if (!donationAmount) {
      return;
    }
    if (!urlDonateAgain || !payment) {
      setInitalValues(getInitialValuesFromApiData(user, payments, donationAmount));
    } else if (payment) {
      setInitalValues(
        getInitialValuesFromLastPayment(payment, payments, donationAmount, user, newPaymentsById)
      );
    }
  }, [user, payments, payment, donationAmount, urlDonateAgain]);

  useEffect(() => {
    if (Object.keys(initialValues).length) {
      setActivePageIndex(
        getInitialActivePageIndex(initialValues as InitialValuesInterface, candidates)
      );
    }
  }, [initialValues]);

  const getPaymentTokenId = values => {
    const paymentToken = values.default_token;
    const savedPayment =
      payments?.find(payment => payment.payment_authorization_token === paymentToken) ||
      (isKeyInObject(paymentToken, newPaymentsById) && newPaymentsById[paymentToken]) ||
      null;
    return savedPayment?.id || null;
  };

  const onSubmit = async values => {
    const isCheckoutMonthly = getIsCheckoutMonthly();
    if (isCheckoutMonthly && !user?.id) {
      // do not allow logged out user to make monthly donation
      return new Promise(resolve => {
        resolve({
          [FORM_ERROR]: 'Please log in if you would like to make a monthly recurring donation',
        });
      });
    }
    let payTokenId = getPaymentTokenId(values);
    if (isCheckoutMonthly && !payTokenId) {
      // if payment was somehow not saved in monthly checkout,
      // save it before submitting donation
      try {
        const last4 =
          values.payment_type === 'credit_card'
            ? values.cc_number.slice(-4)
            : values.ach_account_number?.slice(-4);
        const payment = await postPaymentAPI({
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          payment_authorization_display_name: `${
            values.payment_type === 'credit_card' ? 'Credit Card' : 'Electronic Check'
          }: ...${last4}`,
          payment_authorization_expiration_date:
            values.payment_type === 'credit_card'
              ? `20${values.cc_exp.slice(-2)}-${values.cc_exp.slice(0, 2)}-01`
              : '9999-12-31',
          payment_authorization_method:
            values.payment_type === 'credit_card' ? 'CreditCard' : 'ElectronicCheck',
          payment_authorization_token: values.default_token,
          last_4: last4 || '',
        });
        payTokenId = payment.data.id;
      } catch (error) {
        // do not allow user to make donation without saving payment
        return new Promise(resolve => {
          resolve({
            [FORM_ERROR]:
              'We were unable to save your payment method. Please check payment and try again to make a monthly recurring donation.',
          });
        });
      }
    }
    const requestBody = getPayRequestBody(
      values,
      candidates,
      pages,
      filters,
      getIsCheckoutMonthly(),
      payTokenId
    );
    checkoutLogFirebaseEvent({
      eventName: 'donate_intent',
      userId: user?.id || '',
      candidates,
      donationAmount,
    });
    if (user && getIsInStop2End(requestBody.donor_email, user.id)) {
      handleStop2EndDonationIfNeeded(requestBody.ref_code, requestBody.donor_email, user?.id);
    }
    return new Promise(resolve => {
      dispatch(
        postPayWithToken(
          requestBody,
          payTokenId && !!user?.id ? 'stored_token' : 'one_time_token',
          !!user?.id
        )
      )
        .then(response => {
          resolve(response);
          checkoutLogFirebaseEvent({
            eventName: 'donate_successful',
            userId: user?.id || '',
            candidates,
            donationAmount,
          });
          navigate(getPathWithRef(`confirmation?${getUrlSearchParamsStrWithoutRecipients()}`));
        })
        .catch(error => {
          checkoutLogFirebaseEvent({
            eventName: 'donate_failed',
            userId: user?.id || '',
            candidates,
            donationAmount,
          });
          resolve(getFormErrors(error));
        });
    });
  };

  const onBackToDonatePage = () => {
    saveDonationSelections(candidates, donationAmount);
  };

  const getCanSavePaymentToken = (tokenToSave: string) =>
    !saveNewPaymentIsLoading &&
    !isKeyInObject(tokenToSave, newPaymentsById) &&
    !!tokenToSave &&
    !!user;

  const onNavigateToReviewPage = () => {
    tokensToSave.forEach(tokenToSave => {
      if (!getCanSavePaymentToken(tokenToSave)) {
        return;
      }
      const savePayment = user ? postPayment : postPaymentLoggedOut;
      const savedToken = isKeyInObject(tokenToSave, deTokens)
        ? deTokens[tokenToSave]
        : ({} as DETokenResponseData);
      const last4 =
        savedToken.payment_method === 'credit_card'
          ? savedToken.cc_last_four
          : savedToken.ach_account_number?.slice(-4);
      dispatch(
        savePayment({
          first_name: savedToken.donor_first_name,
          last_name: savedToken.donor_last_name,
          email: savedToken.donor_email,
          payment_authorization_display_name: `${
            savedToken.payment_method === 'credit_card' ? 'Credit Card' : 'Electronic Check'
          }: ...${last4}`,
          payment_authorization_expiration_date:
            savedToken.payment_method === 'credit_card'
              ? `20${savedToken.cc_expiration.slice(-2)}-${savedToken.cc_expiration.slice(0, 2)}-01`
              : '9999-12-31',
          payment_authorization_method:
            savedToken.payment_method === 'credit_card' ? 'CreditCard' : 'ElectronicCheck',
          payment_authorization_token: tokenToSave,
          last_4: last4 || '',
        })
      );
    });
  };

  const backToDonatePath = getIsCheckoutMonthly()
    ? getPathWithRef(`/monthly?${getUrlSearchParamsStrWithoutRecipients()}`)
    : getPathWithRef(`/donate?${getUrlSearchParamsStrWithoutRecipients()}`);

  return (
    <>
      <Row>
        <NavigationContainer $justifyContent="space-between">
          <OlNavigation>
            <li>
              <Link to={backToDonatePath} onClick={onBackToDonatePage}>
                <BodySmall $fontType="semiBold" $color={theme.colors.inkBlue}>
                  1. Amount
                </BodySmall>
                <NavigationAmountDetails $fontType="semiBold" $color={theme.colors.inkBlue}>
                  {` (${formatNumberAsCurrency(donationAmount)})`}
                </NavigationAmountDetails>
              </Link>
            </li>
            {formPages.map(({ navigationTitle }, index) => (
              <li key={index}>
                <button
                  onClick={() => index < activePageIndex && setActivePageIndex(index)}
                  disabled={index >= activePageIndex}
                >
                  <BodySmall
                    $fontType={index <= activePageIndex ? 'semiBold' : 'regular'}
                    $color={
                      index <= activePageIndex ? theme.colors.inkBlue : theme.shadows.black(0.7)
                    }
                  >
                    {navigationTitle}
                  </BodySmall>
                </button>
              </li>
            ))}
          </OlNavigation>
          <ProgressBar $width={getProgressBarWidth(activePageIndex)} />
        </NavigationContainer>
      </Row>
      <div>
        <Body $color={theme.colors.black}>
          {getIsCheckoutMonthly() ? 'Start your monthly ' : 'Complete your '}
        </Body>
        {activePageIndex === 2 ? (
          <Body $color={theme.colors.black}>contribution:</Body>
        ) : (
          <Body $fontType="semiBold" $color={theme.colors.black}>
            {formatNumberAsCurrency(donationAmount)} contribution:
          </Body>
        )}
      </div>
      <Wizard
        initialValues={initialValues}
        backToDonatePath={backToDonatePath}
        onBackToDonatePage={onBackToDonatePage}
        onSubmit={onSubmit}
        activePageIndex={activePageIndex}
        setActivePageIndex={setActivePageIndex}
        isSkipToReviewEnabled={activePageIndex === 0 && payments?.length > 0}
        onNavigateToLastPage={onNavigateToReviewPage}
        validateArray={[
          () => ({}),
          values => {
            const errors = { default_token: undefined as undefined | string };
            if (
              !values.default_token ||
              values.default_token === 'cc_token' ||
              values.default_token === 'ach_token'
            ) {
              errors.default_token = 'Valid payment required';
            }
            return errors;
          },
          () => ({}),
        ]}
      >
        <Details
          candidates={candidates}
          donationAmount={donationAmount}
          isEmployerAddressRequired={getIsEmployerAddressRequiredByCandidates(candidates)}
        />
        <Payment
          candidates={candidates}
          democracyEngineCheckoutUrl={democracyEngineCheckoutUrl}
          donationAmount={donationAmount}
          setTokensToSave={setTokensToSave}
        />
        <Review
          candidates={candidates}
          donationAmount={donationAmount}
          numCandidates={candidates.length}
          backToDonatePath={backToDonatePath}
          onBackToDonatePage={onBackToDonatePage}
          setActivePageIndex={setActivePageIndex}
        />
      </Wizard>
    </>
  );
};
