import { FC, useEffect, useRef, useState } from 'react';
import theme from 'config/theme';
import { ENV_CONFIG } from 'config/environment';
import { Row } from 'components/Row';
import Column from 'components/Column';
import Spacing from 'components/Spacing';
import { Share } from 'components/Share';
import Icon, { IconNames } from 'components/Icon';
import { codeFormatter, getUrlParams } from 'helpers/utils';
import { useAppSelector } from 'helpers/hooks';
import { getAllPages } from 'selectors/pages';
import {
  CheckDescription,
  CheckTitle,
  DescriptionSmall,
  DescriptionToggle,
  IconWrapper,
  LeftBanner,
  LeftDescription,
  LeftDescriptionOath,
  LeftInfoContainer,
  LeftTitle,
  LeftTitlePartner,
  OathImage,
  StyledImage,
} from './styled';

interface Props {
  setRefWidthValue?: (val: number) => void;
}

export const DesktopLeftContainer: FC<Props> = ({ setRefWidthValue }) => {
  const ref = useRef(null);
  const { urlPartner } = getUrlParams();
  const [showImage, setShowImage] = useState(true);
  const [isCondensedDescription, setIsCondensedDescription] = useState(true);
  const allPages = useAppSelector(state => getAllPages(state));

  useEffect(() => {
    const element: any = ref.current;
    if (element?.offsetWidth && setRefWidthValue) {
      setRefWidthValue(element.offsetWidth);
    }
  }, [ref]);

  const changeSrc = ev => {
    ev.target.src = '';
    setShowImage(false);
  };

  const onDescriptionToggle = () => {
    setIsCondensedDescription(isCondensed => !isCondensed);
  };

  const background =
    urlPartner && allPages?.length > 0 && allPages[0].page_misc?.page_background
      ? allPages[0].page_misc.page_background
      : `linear-gradient(179.87deg, ${theme.colors.oathBlue} 9.51%, ${theme.colors.inkBlue} 106.69%)`;

  return (
    <LeftInfoContainer ref={ref} $backgroundColor={background} $isPartnerPage={!!urlPartner}>
      {urlPartner && allPages?.length > 0 ? (
        <>
          <LeftBanner $justifyContent="space-between">
            {showImage && (
              <StyledImage
                alt={`${allPages[0].value} logo`}
                src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/${ENV_CONFIG().CLOUD_STORAGE.TAGS}/tag_${
                  allPages[0].id
                }.png`}
                onError={changeSrc}
              />
            )}
            <Share color={allPages[0].page_misc?.page_color_text} />
          </LeftBanner>
          <h1>
            <LeftTitlePartner
              dangerouslySetInnerHTML={{ __html: allPages[0].value }}
              $color={allPages[0].page_misc?.page_color_text || theme.colors.white}
            />
          </h1>
          <LeftDescription
            $hideForSmall={true}
            dangerouslySetInnerHTML={{
              __html: codeFormatter(allPages[0].desc),
            }}
            $color={allPages[0].page_misc?.page_color_text || theme.colors.white}
          />
          {allPages[0].desc && (
            <DescriptionSmall>
              <LeftDescription
                dangerouslySetInnerHTML={{ __html: codeFormatter(allPages[0].desc) }}
                $isCondensed={isCondensedDescription}
                $color={allPages[0].page_misc?.page_color_text || theme.colors.white}
              />
              <Spacing $size={isCondensedDescription ? 0 : 8} />
              <DescriptionToggle
                onClick={onDescriptionToggle}
                $color={allPages[0].page_misc?.page_color_text || theme.colors.white}
              >
                {isCondensedDescription ? 'View more' : 'View less'}
              </DescriptionToggle>
            </DescriptionSmall>
          )}
        </>
      ) : (
        <>
          <LeftTitle>
            <OathImage
              alt="Oath logo"
              src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/static_content/Oath_logo_simple_White.png`}
            />
          </LeftTitle>
          <LeftDescriptionOath>Your one-stop-shop for political giving.</LeftDescriptionOath>
          <Spacing $size={32} />
          <Row>
            <IconWrapper>
              <Icon name={IconNames.Checkmark} color={theme.colors.inkBlue} size={14} />
            </IconWrapper>
            <Column>
              <CheckTitle>CAUSES YOU CARE FOR</CheckTitle>
              <CheckDescription>
                Oath is the smartest and easiest way to support the causes you care about.
              </CheckDescription>
            </Column>
          </Row>
          <Spacing $size={16} />
          <Row>
            <IconWrapper>
              <Icon name={IconNames.Checkmark} color={theme.colors.inkBlue} size={14} />
            </IconWrapper>
            <Column>
              <CheckTitle>MAXIMUM IMPACT</CheckTitle>
              <CheckDescription>Direct your money where it’s needed most.</CheckDescription>
            </Column>
          </Row>
          <Spacing $size={16} />
          <Row>
            <IconWrapper>
              <Icon name={IconNames.Checkmark} color={theme.colors.inkBlue} size={14} />
            </IconWrapper>
            <Column>
              <CheckTitle>ZERO SPAM</CheckTitle>
              <CheckDescription>Oath never sells or shares your data.</CheckDescription>
            </Column>
          </Row>
        </>
      )}
    </LeftInfoContainer>
  );
};
