import { FC, useEffect, useState } from 'react';
import { ENV_CONFIG } from 'config/environment';
import Column from 'components/Column';
import Spacing from 'components/Spacing';
import { Toast } from 'components/Toast';
import { OrbsBackground } from 'components/Orbs';
import { PopupModal } from 'components/PopupModal';
import { ButtonBrand } from 'components/ButtonBrand';
import { OtherIssues } from 'components/OtherIssues';
import { MaximizeImpact } from 'components/MaximizeImpact';
import { TopIssueCard } from 'components/Cards/TopIssueCard';
import { CandidateType } from 'components/Slate/Candidates/Candidates';
import { DonationTotal } from 'components/Slate/DonationAmount/DonationTotal';
import { getUrlParams } from 'helpers/utils';
import { trackPixel } from 'helpers/pixel';
import logFirebaseEvent from 'helpers/logFirebaseEvent';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { getSessionID } from 'services/storage';
import { IPage } from 'store/pages/types';
import { useShareUrl } from 'hooks/useShareUrl';
import { getSetsData } from 'selectors/sets';
import { getUserData } from 'selectors/user';
import { getSets } from 'store/sets/actions';
import { FundraisingPage } from 'store/sets/types';
import { LatestDonors } from './LatestDonors';
import { CandidatesSection } from './CandidatesSection';
import {
  Content,
  OrbsContainer,
  DetailVideo,
  TranscriptTitle,
  TranscriptDescription,
  RowContainer,
} from './styled';

interface Props {
  allPages: IPage[];
  candidates: CandidateType[];
  onSelectCandidates: (updatedCandidates: CandidateType[]) => void;
  selectedDonationAmount?: number;
  onDonateClick: (selectedTags?: string[]) => void;
  setSelectedDonationAmount: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export const MediaSlate: FC<Props> = ({
  allPages,
  candidates,
  onDonateClick,
  onSelectCandidates,
  selectedDonationAmount,
  setSelectedDonationAmount,
}) => {
  const dispatch = useAppDispatch();
  const { urlPartner } = getUrlParams();
  const [issueSet, setIssueSet] = useState<FundraisingPage | undefined>();
  const [allPageSet, setAllPageSet] = useState<IPage | undefined>();
  const { userData, setsData } = useAppSelector(state => ({
    userData: getUserData(state),
    setsData: getSetsData(state),
  }));
  const { handleShare, isPopupOpen } = useShareUrl(userData?.ref_code, true);
  const handleCandidatesSelected = (index, checkValue: boolean) => {
    const updatedCandidates = [...candidates];
    updatedCandidates[index] = {
      ...candidates[index],
      isSelected: checkValue,
    };
    onSelectCandidates(updatedCandidates);
    logFirebaseEvent(checkValue ? 'land_select_recipient' : 'land_unselect_recipient', {
      timestamp: new Date().toString(),
      recipient_id: candidates[index].misc_ids?.ext_de_url_id,
      session_id: getSessionID(),
    });
  };

  const onDonateButtonClick = () => {
    trackPixel('donation');
    onDonateClick([]);
  };

  useEffect(() => {
    if (allPages?.length && allPages[0]?.set_query_string) {
      dispatch(getSets(allPages[0].set_query_string));
    }
  }, []);

  useEffect(() => {
    if (setsData) {
      setIssueSet(setsData.set_pages.find(set => set.page_id === allPages[0].id));
      setAllPageSet(
        allPages.map(page => ({
          ...page,
          raised:
            setsData.set_pages.find(set => set.page_id === page.id)?.page_fundraising_raised || '',
        }))[0]
      );
    }
  }, [setsData]);

  return (
    <Column $width="100%">
      <Toast />
      <PopupModal autoClose={true} isSelected={isPopupOpen} title="Copied to Clipboard" />
      <OrbsContainer>
        <OrbsBackground />
        <RowContainer $justifyContent="space-between">
          <Column $alignSelf="flex-start" $width="100%">
            <DetailVideo id="maximize-video" controls playsInline>
              <source
                src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/sets/${urlPartner}/video.mp4`}
                type="video/mp4"
              />
            </DetailVideo>
            <Spacing $size={16} />
            <TranscriptTitle>{allPages[0].value}</TranscriptTitle>
            <TranscriptDescription>{allPages[0].desc}</TranscriptDescription>
            <CandidatesSection
              candidates={candidates}
              showInDesktop={true}
              handleCandidatesSelected={handleCandidatesSelected}
            />
          </Column>
          <Spacing $isVertical $size={32} $sizeToSwitch={1000} />
          <Column $justifySelf="center">
            {allPageSet && (
              <TopIssueCard issue={allPageSet} isBottomFaded={true} width="375px">
                <Spacing $size={16} />
                <DonationTotal
                  donationAmounts={allPages[0]?.donation_amounts || []}
                  selectedDonationAmount={selectedDonationAmount}
                  setSelectedDonationAmount={setSelectedDonationAmount}
                  fromIssues
                >
                  <></>
                </DonationTotal>
                <ButtonBrand onClick={onDonateButtonClick}>Donate</ButtonBrand>
                <Spacing $size={8} />
                <ButtonBrand $secondary $hasBorder onClick={handleShare}>
                  Share
                </ButtonBrand>
                <LatestDonors
                  latestDonors={issueSet?.latest_fundraising_transactions}
                  donationCount={issueSet?.page_fundraising_donation_count}
                  issueId={issueSet?.page_id}
                />
              </TopIssueCard>
            )}
          </Column>
        </RowContainer>
      </OrbsContainer>
      <Content $textAlign="center">
        <CandidatesSection
          candidates={candidates}
          showInDesktop={false}
          handleCandidatesSelected={handleCandidatesSelected}
        />
      </Content>
      <OtherIssues setsData={setsData} allPages={allPages} />
      <MaximizeImpact />
    </Column>
  );
};
